import type { StatsV3Influencer } from '~/types/influencer'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export async function getInfluencerCount(): Promise<number> {
  const defaultValue = 800

  try {
    const { count } = await $coreFetch.$get<
      PaginatedApiResponse<StatsV3Influencer>
    >('/influencer/statsv3/?limit=1')

    return count || defaultValue
  } catch (_err) {
    return defaultValue
  }
}
